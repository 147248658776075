<template>
  <div>
    <!--begin::customer-->
    <div>
      <h3 class="card-label">
        {{ $t('inventory_checking.inventory_checking') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5 d-flex">
                <span>{{ $t('inventory_checking.product') }}: </span>
                <span class="ml-2 mr-2">
                                {{ item_name }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventory_checking.sys_qty') }}</label>
                <input type="number" v-model="data.sys_qty" :disabled="true" class="form-control" :class="validation && validation.sys_qty ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.sys_qty" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.sys_qty[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventory_checking.actual_qty') }}</label>
                <input type="number" v-model="data.actual_qty" class="form-control" :class="validation && validation.actual_qty ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.actual_qty" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.actual_qty[0] }}
                            </span>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-inventory-checking",
  data() {
    return {
      mainRoute: 'items/inventory-checking',
      mainRouteDependency: 'base/dependency',

      data: {
        inventory_id: this.$route.query.inventory_id ? this.$route.query.inventory_id : null,
        item_id: this.$route.query.item_id ? this.$route.query.item_id : null,
        sys_qty: null,
        actual_qty: null,
        last_validated_date: null,
      },

      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      validation: null,
      item_name: null,

    };
  },

  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.goBackIndex();
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.goBackIndex();
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },
    goBackIndex() {
      this.$router.push({
        name: 'inventory-checking.index', query: {
          inventory_id: this.$route.query.inventory_id,
        }
      });
    },
    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.item_id = response.data.data.item_id;
        this.data.sys_qty = response.data.data.sys_qty;
        this.data.actual_qty = response.data.data.actual_qty;
        this.data.last_validated_date = response.data.data.last_validated_date;
        this.item_name = response.data.data.item_name;
      });
    },
    getDataItem() {
      ApiService.get(`${this.mainRouteDependency}/inventory_statistics_by_inventory`, {
        params: {
          inventory_id: this.data.inventory_id,
          item_id: this.data.item_id,
        }
      }).then((response) => {
        this.data.sys_qty = response.data.data.available_stock_qty;
        this.item_name = response.data.data.product;
      });
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.inventory_checking"), route: '/items/inventory-checking'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    if (this.idEditing) {
      this.getData();
    } else {
      this.getDataItem();
    }

  },
};
</script>